<template>
  <tr
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
    class="selenium-line-io"
  >
      <ExpandIOFragment
        :props="props"
        :isHover="isHover"
        v-on:ask-select-instructions="selectInstructions"
        v-on:ask-select-item-and-kpi="selectItemIdAndKpi"
      >
      </ExpandIOFragment>
      <RetryFastRetryFragment
        :props="props"
      >
      </RetryFastRetryFragment>
      <InfoIOFragments
        :props="props"
        :dsp="dsp"
        :is-surcouche-v2="false"
      >
      </InfoIOFragments>
      <Localization
        :props="props"
      >
      </Localization>
      <PixelIdFragment
        :props="props"
      >
      </PixelIdFragment>
      <ConstraintsToRespect
        :props="props"
      >
      </ConstraintsToRespect>
      <AlgoStrategy
        :props="props"
      >
      </AlgoStrategy>
      <DSPOverwriting
        :props="props"
      >
      </DSPOverwriting>
      <td class="duplicate-rm-box">
        <div v-if="isHover" class="edit-duplicate-box">
          <v-row wrap no-gutters>
            <v-col cols="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                      v-ripple="{ class: `primary--text`, center: true }"
                      small
                      class="mr-2 elevation-3"
                      @click="askForEditItem(props.item)"
                      v-on="on"
                      :disabled="dataIsLoading"
                  >
                      edit
                  </v-icon>
                </template>
                <span>Edit IO</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" class="py-0" v-show="$store.getters.isInstructionStratEnabled">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-ripple="{ class: `primary--text`, center: true }"
                    small
                    class="mr-2 mt-0 elevation-3"
                    @click="askForOpenStrat(props.item.io)"
                    v-on="on"
                    :disabled="dataIsLoading"
                  >
                    remove_red_eye
                  </v-icon>
                </template>
                <span>Open outcomes for all instructions of this IO</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-ripple="{ class: `primary--text`, center: true }"
                    small
                    @click="removeScibids()"
                    class="mr-2 mt-0 elevation-3"
                    v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Remove Scibids Model</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </td>
  </tr>
</template>

<script>
import { ExpandIOFragment, Localization, PixelIdFragment, AlgoStrategy, DSPOverwriting, ConstraintsToRespect, InfoIOFragments } from './Fragments'
import RetryFastRetryFragment from '@/components/TableComponents/Line/Fragments/RetryFastRetryFragment'
import InstructionGetterSetter from '@/models/Overlay/InstructionGetterSetter'
import RemoveScibidsModel from '@/models/RemoveScibids/RemoveScibidsModel'

export default {
  name: 'LineIO',
  props: ['selected', 'props', 'dsp', 'dataIsLoading'],
  components: {
    RetryFastRetryFragment,
    ExpandIOFragment,
    Localization,
    AlgoStrategy,
    DSPOverwriting,
    PixelIdFragment,
    ConstraintsToRespect,
    InfoIOFragments
  },
  data: function () {
    return {
      checkBoxSelected: false,
      isHover: false,
      insGetterSetter: new InstructionGetterSetter(this.props.item, this.dsp)
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    selectInstructions: function (ids = [], state) {
      this.$emit('select-instructions', ids, state)
    },
    selectItemIdAndKpi: function (id, instruction, state) {
      this.$emit('select-item-and-kpi', id, instruction, state)
    },
    askForEditItem (item) {
      this.$emit('edit-item', item)
    },
    askForDuplicateIO (item) {
      this.$emit('duplicate-io', item)
    },
    askForOpenStrat (insertionOrderId) {
      this.$emit('open-strat', insertionOrderId)
    },
    removeScibids () {
      let removeScibidsData = {
        ioID: this.props.item.io,
        memberExtID: this.insGetterSetter.clientIdPerDsp,
        dsp: this.dsp,
        removeScibidsModel: new RemoveScibidsModel()
      }
      this.$emit('removeScibids', removeScibidsData)
    }
  },
  computed: {
  },
  watch: {

  }
}
</script>

<style>
.edit-duplicate-box {
  text-align: right;
}

.duplicate-rm-box {
  width: 8em;
}
.v-data-table > .v-data-table__wrapper .selenium-line-io > td {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
