
import { defineComponent, PropType } from 'vue'
import { BulkOttoParams, BulkToggleValue } from '@/components/TableComponents/Form/BulkOtto/BulkOttoComponent.vue'
import BulkToggle from '@/components/TableComponents/Form/BulkOtto/BulkToggle.vue'

export default defineComponent({
  name: 'BulkOttoIo',
  components: { BulkToggle },
  props: {
    value: {
      type: Object as PropType<BulkOttoParams>,
      required: true
    }
  },
  methods: {
    emitUpdate (field: keyof BulkOttoParams, value: BulkToggleValue) {
      this.$emit('input', { ...this.value, [field]: value })
    }
  }
})
