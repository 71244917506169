
import { defineComponent, PropType } from 'vue'
import { BulkToggleValue } from '@/components/TableComponents/Form/BulkOtto/BulkOttoComponent.vue'

export default defineComponent({
  name: 'BulkToggle',
  props: {
    value: {
      type: [Object as () => Boolean, String] as PropType<BulkToggleValue>,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    emitUpdate (value: BulkToggleValue) {
      this.$emit('change', value)
    }
  }
})
