
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'BulkOttoVMenu',
  props: {
    fullIoList: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  methods: {
    removeIoFromList (io: string) {
      this.$emit('remove-io', io)
    }
  }
})
